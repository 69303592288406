import React, { Fragment } from "react";
import "../assets/sass/style.scss";

import Seo from "../blocks/seo/Seo";

import { Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import BlockContact from "../blocks/contact/BlockContact";
import Video from "../components/video/Video";

import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

const ProjectDetailTemplate = (props) => {
  const project = props.pageContext.projectData;

  console.log(project);
  return (
    <Fragment>
      <Seo
        title={project.title}
        keywords="" // TODO: add keywords depending on project (pull from CMS)
        bodyAttributes={{
          class: "single single-portfolio bg-fixed bg-line",
        }}
      />

      <Header />

      <main id="main" className="site-main bg-half-ring-right bg-half-ring-top">
        <section id="page-content">
          <div className="wrapper">
            <div id="single">
              <Link
                className="btn btn-link border-0 p-0 transform-scale-h"
                style={{ marginBottom: "40px" }}
                to="/projects"
              >
                <i className="icon-c icon-arrow-left" />
                See all projects
              </Link>
              <h1 className="small">
                {project.title}
                <span className="dot">.</span>
              </h1>
              {project.heroImage &&
              (project.heroImage.mimeType === "image/jpeg" ||
                project.heroImage.mimeType === "image/png") ? (
                <div className="hero-image">
                  <GatsbyImage
                    image={getImage(project.heroImage.gatsbyImageData)}
                    // TODO: fix alt text
                    alt="text comes here"
                  />
                </div>
              ) : (
                ""
              )}
              {project.heroImage &&
              (project.heroImage.mimeType === "video/quicktime" ||
                project.heroImage.mimeType === "video/mp4") ? (
                <div className="hero-image">
                  <Video fileUrl={project.heroImage.file.url} />
                </div>
              ) : (
                ""
              )}
              <div className="row gutter-width-lg single-content">
                {project.detailImages && project.detailImages.length > 0 ? (
                  <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12">
                    {project.detailImages &&
                      project.detailImages.map((detailAsset, key) => {
                        if (
                          detailAsset.mimeType === "image/jpeg" ||
                          detailAsset.mimeType === "image/png"
                        ) {
                          const image = getImage(detailAsset.gatsbyImageData);
                          return (
                            <div className="detail-image-wrapper" key={key}>
                              <div className="detail-image" key={key}>
                                <GatsbyImage
                                  image={image}
                                  alt={detailAsset.description}
                                />
                              </div>
                              <p className="p-small">
                                {detailAsset.description}
                              </p>
                            </div>
                          );
                        }
                        if (
                          detailAsset.mimeType === "video/quicktime" ||
                          detailAsset.mimeType === "video/mp4"
                        ) {
                          return (
                            <div className="detail-image-wrapper" key={key}>
                              <Video fileUrl={detailAsset.file.url} />
                              <p className="p-small">
                                {detailAsset.description}
                              </p>
                            </div>
                          );
                        }
                        return null;
                      })}
                  </div>
                ) : (
                  ""
                )}

                <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-centered">
                  <ul className="clear-fix mb-0 text-left">
                    {project.year ? (
                      <li>
                        <p>Year: {project.year}</p>
                      </li>
                    ) : (
                      ""
                    )}
                    {project.roles ? (
                      <li>
                        <p>Roles: {project.roles}</p>
                      </li>
                    ) : (
                      ""
                    )}
                    {project.tech ? (
                      <li>
                        <p>Tech: {project.tech}</p>
                      </li>
                    ) : (
                      ""
                    )}
                    {project.company ? (
                      <li>
                        <p>Company: {project.company}</p>
                      </li>
                    ) : (
                      ""
                    )}
                    {project.client ? (
                      <li>
                        <p>Client: {project.client}</p>
                      </li>
                    ) : (
                      ""
                    )}
                    <div className="button-row">
                      {project.projectLink ? (
                        <a
                          className="btn btn-outline-dark btn-sm transform-scale-h"
                          href={project.projectLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View Project
                        </a>
                      ) : (
                        ""
                      )}
                      {project.sourceCodeLink ? (
                        <a
                          className="btn btn-outline-dark btn-sm transform-scale-h"
                          href={project.sourceCodeLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View Source Code
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </ul>
                  <hr></hr>

                  <div className="description description-richtext">
                    {renderRichText(project.description, {})}
                  </div>
                </div>
              </div>
            </div>
            <BlockContact goalId="XGFKJIDX" />
          </div>
        </section>
      </main>

      <Footer />
    </Fragment>
  );
};

export default ProjectDetailTemplate;
